import { createStore } from 'vuex'
import introduce from "./modules/introduce"
import data from "@/store/modules/meun"
import products from './modules/products'

export default createStore({
  state: {
    // list:JSON.parse(localStorage.getItem('AHK')),
    list:'',
    // NHK
    AHk:'',
    acid:"",
    reagent:'',
    library:'',
    Colloidalgold:'',
    // 详情数据
    details:JSON.parse(sessionStorage.getItem('detailslist'))
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    introduce:introduce,
    meundata:data,
    products:products
  }
})



const products={
    nameespaced: true,
       state:{
            AHk:[
                {
                    id:1,
                    title:'全自动毛细管电泳仪',
                    moreacid:[
                        {
                            id:101,
                            title:"胶体金检测产品",
                            img:[
                                {
                                    img:'1'
                                },
                                {
                                    img:'2'
                                },
                                {
                                    img:'3'
                                }
                            ],
                            instroduce:[
                                {
                                    name:'产品名称',
                                    font:'全自动毛细管电泳仪'
                                },
                                {
                                    name:'产品型号',
                                    font:'NHK-A5'
                                },
                                {
                                    name:'预期用途',
                                    font:'核酸片段大小、及浓度定量分析'
                                },
                            ],
                            scene:[
                                {
                                    name:'新一代测序',
                                    font:'可靠的NGS文库质量控制分析。同时测定分子大小、浓度并进行降解检测'
                                },
                                {
                                    name:'PCR片段',
                                    font:'准确测定分子大小，相似的PCR片段长度具有出色的分辨率'
                                },
                                {
                                    name:'游离DNA',
                                    font:'出色地分离三个或更多cfDNA核小体片段和gDNA污染，具有优异的分辨率'
                                },
                                {
                                    name:'CRISPR/Cas9',
                                    font:'快速确定试剂质量和基因编辑成功与否'
                                },
                                {
                                    name:'基因组DNA',
                                    font:'验证用于下游处理的gDNA起始材料的质量和分子大小'
                                },
                                {
                                    name:'RNA分析',
                                    font:'可靠地进行信使RNA、核糖体RNA、microRNA和总RNA的质量分析和定量分析'
                                }
                            ],
                            advantage:[
                                {
                                    icon:'',
                                    font:'毛细管阵列可互换的灵活性，实现不同的分辨率与应用'
                                },
                                {
                                    icon:'---',
                                    font:'可以加载3块96孔样品板，在无人干预的情况下实现288个样本分析，并可以任何顺序开展检测'
                                },
                                {
                                    icon:'---',
                                    font:'可以装载两种不同的凝胶基质，实现无需人工干预的不同样本类型分析间的切换'
                                },
                                {
                                    icon:'---',
                                    font:'专业软件配合不同试剂盒提供基因组DNA完整值（GQN）、RNA完整值(RQN)'
                                },
                                {
                                    icon:'---',
                                    font:'应用覆盖基因组DNA、大/小片段DNA、cfDNA、NGS文库质控、微卫星、PCR片段、RNA等'
                                },
                                {
                                    icon:'---',
                                    font:'可靠的弥散条带分析可提供准确的摩尔浓度计算结果'
                                },
                                {
                                    icon:'---',
                                    font:'清晰的结果，分辨率高达3bp'
                                }
                            ]
                        },
                        {
                            id:202,
                            title:"核酸提取或纯化试剂",
                            img:[
                                {
                                    img:'1'
                                },
                                {
                                    img:'2'
                                },
                                {
                                    img:'3'
                                }
                            ],
                            instroduce:[
                               {
                                name:"产品名称",
                                font:'核酸提取或纯化试剂'
                               },
                               {
                                name:"产品规格",
                                font:'48人份/盒'
                               },
                               {
                                name:"适用样本类型",
                                font:'血清、血浆、体液'
                               },
                            ],
                            scene:[
                                {
                                    icon:"————",
                                    font:'用于血清、血浆和体液样本中游离核酸的提取、富集、纯化等步骤,其处理后的产物可用于PCR、NGS等临床体外检测使用。动毛细管电泳仪NHK-A5进行核酸质量检测）。'
                                },
                                {
                                    icon:"————",
                                    font:'血清、血浆和体液样本经裂解液和蛋白酶作用后，采用磁珠对核酸进行富集纯化后，通过洗脱液将核酸从磁珠上洗脱下来。提取后的核酸DNA OD 260/280应在1.7~1.9；毛细管电泳片段在160-180bp（推荐四川诺瀚康科技有限公司全自动毛细管电泳仪NHK-A5）'
                                }
                            ]
                        },
                        {
                            id:203,
                            title:"核酸提取或纯化试剂",
                            img:[
                                {
                                    img:'1'
                                },
                                {
                                    img:'2'
                                },
                                {
                                    img:'3'
                                }
                            ],
                            instroduce:[
                               {
                                name:"产品名称",
                                font:'核酸提取或纯化试剂'
                               },
                               {
                                name:"产品规格",
                                font:'48人份/盒'
                               },
                               {
                                name:"适用样本类型",
                                font:'血清、血浆、体液'
                               },
                            ],
                            scene:[
                                {
                                    icon:"————",
                                    font:'用于血清、血浆和体液样本中游离核酸的提取、富集、纯化等步骤,其处理后的产物可用于PCR、NGS等临床体外检测使用。动毛细管电泳仪NHK-A5进行核酸质量检测）。'
                                },
                                {
                                    icon:"————",
                                    font:'血清、血浆和体液样本经裂解液和蛋白酶作用后，采用磁珠对核酸进行富集纯化后，通过洗脱液将核酸从磁珠上洗脱下来。提取后的核酸DNA OD 260/280应在1.7~1.9；毛细管电泳片段在160-180bp（推荐四川诺瀚康科技有限公司全自动毛细管电泳仪NHK-A5）'
                                }
                            ]
                        },
                    ],
                }
            ],
            acid:[
                {
                    id:2,
                    title:'核酸提取试剂盒',
                    moreacid:[
                        {
                            id:201,
                            title:"游离DNA提取试剂盒",
                            img:[
                                {
                                    img:'1'
                                },
                                {
                                    img:'2'
                                },
                                {
                                    img:'3'
                                }
                            ],
                            instroduce:[
                               {
                                name:"产品名称",
                                font:'游离DNA提取试剂盒'
                               },
                               {
                                name:"产品规格",
                                font:'48人份/盒'
                               },
                               {
                                name:"适用样本类型",
                                font:'血清、血浆、体液'
                               },
                            ],
                            scene:[
                                {
                                    icon:"————",
                                    font:'用于血清、血浆和体液样本中游离核酸的提取、富集、纯化等步骤,其处理后的产物可用于PCR、NGS等临床体外检测使用。动毛细管电泳仪NHK-A5进行核酸质量检测）。'
                                },
                                {
                                    icon:"————",
                                    font:'血清、血浆和体液样本经裂解液和蛋白酶作用后，采用磁珠对核酸进行富集纯化后，通过洗脱液将核酸从磁珠上洗脱下来。提取后的核酸DNA OD 260/280应在1.7~1.9；毛细管电泳片段在160-180bp（推荐四川诺瀚康科技有限公司全自动毛细管电泳仪NHK-A5）'
                                }
                            ]
                        },
                        {
                            id:202,
                            title:"核酸提取或纯化试剂",
                            img:[
                                {
                                    img:'1'
                                },
                                {
                                    img:'2'
                                },
                                {
                                    img:'3'
                                }
                            ],
                            instroduce:[
                               {
                                name:"产品名称",
                                font:'核酸提取或纯化试剂'
                               },
                               {
                                name:"产品规格",
                                font:'48人份/盒'
                               },
                               {
                                name:"适用样本类型",
                                font:'血清、血浆、体液'
                               },
                            ],
                            scene:[
                                {
                                    icon:"————",
                                    font:'用于血清、血浆和体液样本中游离核酸的提取、富集、纯化等步骤,其处理后的产物可用于PCR、NGS等临床体外检测使用。动毛细管电泳仪NHK-A5进行核酸质量检测）。'
                                },
                                {
                                    icon:"————",
                                    font:'血清、血浆和体液样本经裂解液和蛋白酶作用后，采用磁珠对核酸进行富集纯化后，通过洗脱液将核酸从磁珠上洗脱下来。提取后的核酸DNA OD 260/280应在1.7~1.9；毛细管电泳片段在160-180bp（推荐四川诺瀚康科技有限公司全自动毛细管电泳仪NHK-A5）'
                                }
                            ]
                        },
                        {
                            id:203,
                            title:"核酸提取或纯化试剂",
                            img:[
                                {
                                    img:'1'
                                },
                                {
                                    img:'2'
                                },
                                {
                                    img:'3'
                                }
                            ],
                            instroduce:[
                               {
                                name:"产品名称",
                                font:'核酸提取或纯化试剂'
                               },
                               {
                                name:"产品规格",
                                font:'48人份/盒'
                               },
                               {
                                name:"适用样本类型",
                                font:'血清、血浆、体液'
                               },
                            ],
                            scene:[
                                {
                                    icon:"————",
                                    font:'用于血清、血浆和体液样本中游离核酸的提取、富集、纯化等步骤,其处理后的产物可用于PCR、NGS等临床体外检测使用。动毛细管电泳仪NHK-A5进行核酸质量检测）。'
                                },
                                {
                                    icon:"————",
                                    font:'血清、血浆和体液样本经裂解液和蛋白酶作用后，采用磁珠对核酸进行富集纯化后，通过洗脱液将核酸从磁珠上洗脱下来。提取后的核酸DNA OD 260/280应在1.7~1.9；毛细管电泳片段在160-180bp（推荐四川诺瀚康科技有限公司全自动毛细管电泳仪NHK-A5）'
                                }
                            ]
                        },
                    ],
                }
            ],
            reagent:[
                {
                    id:2,
                    title:'扩增试剂',
                    moreacid:[
                        {
                            id:201,
                            title:"恒温PCR预混液（恒温扩增试剂盒）",
                            img:[
                                {
                                    img:'1'
                                },
                                {
                                    img:'2'
                                },
                                {
                                    img:'3'
                                }
                            ],
                            instroduce:[
                               {
                                name:"产品名称",
                                font:'恒温PCR预混液（恒温扩增试剂盒）'
                               },
                               {
                                name:"产品规格",
                                font:'48人份/盒'
                               },
                               {
                                name:"适用样本类型",
                                font:'血清、血浆、体液'
                               },
                            ],
                            scene:[
                                {
                                    icon:"————",
                                    font:'用于血清、血浆和体液样本中游离核酸的提取、富集、纯化等步骤,其处理后的产物可用于PCR、NGS等临床体外检测使用。动毛细管电泳仪NHK-A5进行核酸质量检测）。'
                                },
                                {
                                    icon:"————",
                                    font:'血清、血浆和体液样本经裂解液和蛋白酶作用后，采用磁珠对核酸进行富集纯化后，通过洗脱液将核酸从磁珠上洗脱下来。提取后的核酸DNA OD 260/280应在1.7~1.9；毛细管电泳片段在160-180bp（推荐四川诺瀚康科技有限公司全自动毛细管电泳仪NHK-A5）'
                                }
                            ]
                        }
                    ],
                }
            ],
            library:[
                {
                    id:2,
                    title:'扩增试剂',
                    moreacid:[
                        {
                            id:201,
                            title:"恒温PCR预混液（恒温扩增试剂盒）",
                            img:[
                                {
                                    img:'1'
                                },
                                {
                                    img:'2'
                                },
                                {
                                    img:'3'
                                }
                            ],
                            instroduce:[
                               {
                                name:"产品名称",
                                font:'恒温PCR预混液（恒温扩增试剂盒）'
                               },
                               {
                                name:"产品规格",
                                font:'48人份/盒'
                               },
                               {
                                name:"适用样本类型",
                                font:'血清、血浆、体液'
                               },
                            ],
                            scene:[
                                {
                                    icon:"————",
                                    font:'用于血清、血浆和体液样本中游离核酸的提取、富集、纯化等步骤,其处理后的产物可用于PCR、NGS等临床体外检测使用。动毛细管电泳仪NHK-A5进行核酸质量检测）。'
                                },
                                {
                                    icon:"————",
                                    font:'血清、血浆和体液样本经裂解液和蛋白酶作用后，采用磁珠对核酸进行富集纯化后，通过洗脱液将核酸从磁珠上洗脱下来。提取后的核酸DNA OD 260/280应在1.7~1.9；毛细管电泳片段在160-180bp（推荐四川诺瀚康科技有限公司全自动毛细管电泳仪NHK-A5）'
                                }
                            ]
                        }
                    ],
                }
            ],
            Colloidalgold:[
                {
                    id:2,
                    title:'胶体金检测产品',
                    moreacid:[
                        {
                            id:201,
                            title:"胶体金检测产品",
                            img:[
                                {
                                    img:'1'
                                },
                                {
                                    img:'2'
                                },
                                {
                                    img:'3'
                                }
                            ],
                            instroduce:[
                               {
                                name:"产品名称",
                                font:'游离DNA提取试剂盒'
                               },
                               {
                                name:"产品规格",
                                font:'48人份/盒'
                               },
                               {
                                name:"适用样本类型",
                                font:'血清、血浆、体液'
                               },
                            ],
                            scene:[
                                {
                                    icon:"————",
                                    font:'用于血清、血浆和体液样本中游离核酸的提取、富集、纯化等步骤,其处理后的产物可用于PCR、NGS等临床体外检测使用。动毛细管电泳仪NHK-A5进行核酸质量检测）。'
                                },
                                {
                                    icon:"————",
                                    font:'血清、血浆和体液样本经裂解液和蛋白酶作用后，采用磁珠对核酸进行富集纯化后，通过洗脱液将核酸从磁珠上洗脱下来。提取后的核酸DNA OD 260/280应在1.7~1.9；毛细管电泳片段在160-180bp（推荐四川诺瀚康科技有限公司全自动毛细管电泳仪NHK-A5）'
                                }
                            ]
                        },
                        {
                            id:202,
                            title:"核酸提取或纯化试剂",
                            img:[
                                {
                                    img:'1'
                                },
                                {
                                    img:'2'
                                },
                                {
                                    img:'3'
                                }
                            ],
                            instroduce:[
                               {
                                name:"产品名称",
                                font:'核酸提取或纯化试剂'
                               },
                               {
                                name:"产品规格",
                                font:'48人份/盒'
                               },
                               {
                                name:"适用样本类型",
                                font:'血清、血浆、体液'
                               },
                            ],
                            scene:[
                                {
                                    icon:"————",
                                    font:'用于血清、血浆和体液样本中游离核酸的提取、富集、纯化等步骤,其处理后的产物可用于PCR、NGS等临床体外检测使用。动毛细管电泳仪NHK-A5进行核酸质量检测）。'
                                },
                                {
                                    icon:"————",
                                    font:'血清、血浆和体液样本经裂解液和蛋白酶作用后，采用磁珠对核酸进行富集纯化后，通过洗脱液将核酸从磁珠上洗脱下来。提取后的核酸DNA OD 260/280应在1.7~1.9；毛细管电泳片段在160-180bp（推荐四川诺瀚康科技有限公司全自动毛细管电泳仪NHK-A5）'
                                }
                            ]
                        },
                        {
                            id:203,
                            title:"核酸提取或纯化试剂",
                            img:[
                                {
                                    img:'1'
                                },
                                {
                                    img:'2'
                                },
                                {
                                    img:'3'
                                }
                            ],
                            instroduce:[
                               {
                                name:"产品名称",
                                font:'核酸提取或纯化试剂'
                               },
                               {
                                name:"产品规格",
                                font:'48人份/盒'
                               },
                               {
                                name:"适用样本类型",
                                font:'血清、血浆、体液'
                               },
                            ],
                            scene:[
                                {
                                    icon:"————",
                                    font:'用于血清、血浆和体液样本中游离核酸的提取、富集、纯化等步骤,其处理后的产物可用于PCR、NGS等临床体外检测使用。动毛细管电泳仪NHK-A5进行核酸质量检测）。'
                                },
                                {
                                    icon:"————",
                                    font:'血清、血浆和体液样本经裂解液和蛋白酶作用后，采用磁珠对核酸进行富集纯化后，通过洗脱液将核酸从磁珠上洗脱下来。提取后的核酸DNA OD 260/280应在1.7~1.9；毛细管电泳片段在160-180bp（推荐四川诺瀚康科技有限公司全自动毛细管电泳仪NHK-A5）'
                                }
                            ]
                        },
                    ],
                }
            ],

            // 详情数据
            details:[
                {
                    id:101,
                    title:"全自动毛细管电泳仪",
                    img:[
                        // {
                        //     img:require('../../assets/image/product/nhk-a5_mainpic_1@1x.webp')
                        // },
                        // {
                        //     img:require('../../assets/image/product/nhk-a5_mainpic_2@1x.webp')
                        // },
                        // {
                        //     img:require('../../assets/image/product/nhk-a5_mainpic_3@1x.webp')
                        // },
                        // replace
                        {
                            img:require('@/assets/image/home/nhka5_left_replace.png')
                        },
                         {
                            img:require('@/assets/image/home/nhka5_mid_replace.png')
                        },
                         {
                            img:require('@/assets/image/home/nhka5_right_replace.png')
                        }
                    ],
                  data:[
                    {
                        id:1,
                        title:'产品介绍',
                        instroduce:[
                            {
                                name:'产品名称',
                                font:'全自动毛细管电泳仪'
                            },
                            {
                                name:'产品型号',
                                font:'NHK-A5'
                            },
                            {
                                name:'预期用途',
                                font:'核酸片段大小及浓度定量分析'
                            },
                        ],
                       
                    },
                    {
                        id:2,
                        title:'产品用途',
                        instroduce:[
                            {
                                name:'新一代测序',
                                font:'可靠的NGS文库质量控制分析。同时测定分子大小、浓度并进行降解检测'
                            },
                            {
                                name:'PCR片段',
                                font:'准确测定分子大小，对相似的PCR片段长度具有出色的分辨率'
                            },
                            {
                                name:'游离DNA',
                                font:'出色地分离三个或更多cfDNA核小体片段和gDNA污染，具有优异的分辨率'
                            },
                            {
                                name:'CRISPR/Cas9',
                                font:'快速确定试剂质量和基因编辑成功与否'
                            },
                            {
                                name:'基因组DNA',
                                font:'验证用于下游处理的gDNA起始材料的质量和分子大小'
                            },
                            {
                                name:'RNA分析',
                                font:'可靠地进行信使RNA、核糖体RNA、microRNA和总RNA的质量分析和定量分析'
                            }
                        ],
                    },
                    {
                        id:3,
                        title:'产品优势',
                        instroduce:[
                            {
                                name:'',
                                font:'毛细管阵列可互换的灵活性，实现不同的分辨率与应用'
                            },
                            {
                                name:'',
                                font:'可以加载3块96孔样品板，在无人干预的情况下实现288个样本分析，并可以任何顺序开展检测'
                            },
                            {
                                name:'',
                                font:'可以装载两种不同的凝胶基质，实现无需人工干预的不同样本类型分析间的切换'
                            },
                            {
                                name:'',
                                font:'专业软件配合不同试剂盒提供基因组DNA完整值（GQN）、RNA完整值(RQN)'
                            },
                            {
                                name:'',
                                font:'应用覆盖基因组DNA、大/小片段DNA、cfDNA、NGS文库质控、微卫星、PCR片段、RNA等'
                            },
                            {
                                name:'',
                                font:'可靠的弥散条带分析可提供准确的摩尔浓度计算结果'
                            },
                            {
                                name:'',
                                font:'清晰的结果，分辨率高达3bp'
                            }
                        ]
                    }
                  ]
                },
                {
                    id:201,
                    title:"核酸提取或纯化试剂",
                    img:[
                        {
                            img:require('../../assets/image/product/cfDNA_kit@2x.webp')
                        },
                    ],


                    data:[
                        {
                            id:1,
                            title:'产品介绍',
                            instroduce:[
                                {
                                    name:'产品名称',
                                    font:'核酸提取或纯化试剂'
                                },
                                {
                                    name:'产品规格',
                                    font:'48人份/盒'
                                },
                                {
                                    name:'适用样本类型',
                                    font:'血清、血浆、体液'
                                },
                            ],
                           
                        },
                        {
                            id:2,
                            title:'预期用途',
                            instroduce:[
                                {
                                    name:'',
                                    font:'用于血清、血浆和体液样本中游离核酸的提取、富集、纯化等步骤,其处理后的产物可用于PCR、NGS等临床体外检测使用。'
                                }
                            ],
                        },
                        {
                            id:3,
                            title:'产品优势',
                            instroduce:[
                                {
                                    name:'',
                                    font:'提取产量高：最优化的操作流程，最大程度获得游离DNA，回收率高。'
                                },
                                {
                                    name:'',
                                    font:'有效去除PCR抑制剂和蛋白质污染物。'
                                },
                                {
                                    name:'',
                                    font:'最低量的洗脱体积：洗脱体积可低至20μL，保证较高的核酸浓度。'
                                },
                                {
                                    name:'',
                                    font:'灵活的样本处理量：可从1~5mL的无细胞体液样本中提取游离DNA。'
                                }
                            ]
                        }
                      ]
                },
                // 为申请
                // {
                //     id:202,
                //     title:"核酸提取或纯化试剂",
                //     img:[
                //         {
                //             img:require('../../assets/image/product/cfDNA_kit@2x.webp')
                //         },
                //     ],
                //     data:[
                //         {
                //             id:1,
                //             title:'产品介绍',
                //             instroduce:[
                //                 {
                //                     name:'产品名称',
                //                     font:'全自动毛细管电泳仪'
                //                 },
                //                 {
                //                     name:'产品型号',
                //                     font:'NHK-A5'
                //                 },
                //                 {
                //                     name:'预期用途',
                //                     font:'核酸片段大小、及浓度定量分析'
                //                 },
                //             ],
                           
                //         },
                //         {
                //             id:2,
                //             title:'产品用途',
                //             instroduce:[
                //                 {
                //                     name:'新一代测序',
                //                     font:'可靠的NGS文库质量控制分析。同时测定分子大小、浓度并进行降解检测'
                //                 },
                //                 {
                //                     name:'PCR片段',
                //                     font:'准确测定分子大小，相似的PCR片段长度具有出色的分辨率'
                //                 },
                //                 {
                //                     name:'游离DNA',
                //                     font:'出色地分离三个或更多cfDNA核小体片段和gDNA污染，具有优异的分辨率'
                //                 },
                //                 {
                //                     name:'CRISPR/Cas9',
                //                     font:'快速确定试剂质量和基因编辑成功与否'
                //                 },
                //                 {
                //                     name:'基因组DNA',
                //                     font:'验证用于下游处理的gDNA起始材料的质量和分子大小'
                //                 },
                //                 {
                //                     name:'RNA分析',
                //                     font:'可靠地进行信使RNA、核糖体RNA、microRNA和总RNA的质量分析和定量分析'
                //                 }
                //             ],
                //         },
                //         {
                //             id:3,
                //             title:'产品优势',
                //             instroduce:[
                //                 {
                //                     name:'---',
                //                     font:'毛细管阵列可互换的灵活性，实现不同的分辨率与应用'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'可以加载3块96孔样品板，在无人干预的情况下实现288个样本分析，并可以任何顺序开展检测'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'可以装载两种不同的凝胶基质，实现无需人工干预的不同样本类型分析间的切换'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'专业软件配合不同试剂盒提供基因组DNA完整值（GQN）、RNA完整值(RQN)'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'应用覆盖基因组DNA、大/小片段DNA、cfDNA、NGS文库质控、微卫星、PCR片段、RNA等'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'可靠的弥散条带分析可提供准确的摩尔浓度计算结果'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'清晰的结果，分辨率高达3bp'
                //                 }
                //             ]
                //         }
                //       ]
                // },
                {
                    id:203,
                    title:"核酸提取试剂",
                    img:[
                        {
                            img:require('../../assets/image/product/microbial_kit@2x.webp')
                        },
                    ],
                    data:[
                        {
                            id:1,
                            title:'产品介绍',
                            instroduce:[
                                {
                                    name:'产品名称',
                                    font:'核酸提取试剂'
                                },
                                {
                                    name:'产品规格',
                                    font:'1次/盒、2次/盒、12次/盒、48次/盒'
                                },
                                {
                                    name:'适用样本类型',
                                    font:'鼻咽、口咽拭子或唾液'
                                },
                                {
                                    name:'医疗器械备案号',
                                    font:'川蓉械备20210165号'
                                },
                            ],
                           
                        },
                        {
                            id:2,
                            title:'产品用途',
                            instroduce:[
                                {
                                    name:'',
                                    font:'用于鼻咽、口咽拭子或唾液中病原微生物核酸的提取。提取的核酸可用于PCR、测序等临床体外检测使用。'
                                }
                            ],
                        },
                        {
                            id:3,
                            title:'产品优势',
                            instroduce:[
                                {
                                    name:'',
                                    font:'一步完成对样本中病原微生物核酸的释放，释放的核酸满足后续PCR、测序等临床体外检测使用。'
                                },
                                {
                                    name:'',
                                    font:'简便、快速。'
                                }
                            ]
                        }
                      ]
                },
                // {
                //     id:204,
                //     title:"核酸提取试剂（微生物核酸提取试剂盒）",
                //     img:[
                //         {
                //             img:require('../../assets/image/product/microbial_kit@2x.webp')
                //        }
                //     ],
                //     data:[
                //         {
                //             id:1,
                //             title:'产品介绍',
                //             instroduce:[
                //                 {
                //                     name:'产品名称',
                //                     font:'全自动毛细管电泳仪'
                //                 },
                //                 {
                //                     name:'产品型号',
                //                     font:'NHK-A5'
                //                 },
                //                 {
                //                     name:'预期用途',
                //                     font:'核酸片段大小、及浓度定量分析'
                //                 },
                //             ],
                           
                //         },
                //         {
                //             id:2,
                //             title:'产品用途',
                //             instroduce:[
                //                 {
                //                     name:'新一代测序',
                //                     font:'可靠的NGS文库质量控制分析。同时测定分子大小、浓度并进行降解检测'
                //                 },
                //                 {
                //                     name:'PCR片段',
                //                     font:'准确测定分子大小，相似的PCR片段长度具有出色的分辨率'
                //                 },
                //                 {
                //                     name:'游离DNA',
                //                     font:'出色地分离三个或更多cfDNA核小体片段和gDNA污染，具有优异的分辨率'
                //                 },
                //                 {
                //                     name:'CRISPR/Cas9',
                //                     font:'快速确定试剂质量和基因编辑成功与否'
                //                 },
                //                 {
                //                     name:'基因组DNA',
                //                     font:'验证用于下游处理的gDNA起始材料的质量和分子大小'
                //                 },
                //                 {
                //                     name:'RNA分析',
                //                     font:'可靠地进行信使RNA、核糖体RNA、microRNA和总RNA的质量分析和定量分析'
                //                 }
                //             ],
                //         },
                //         {
                //             id:3,
                //             title:'产品优势',
                //             instroduce:[
                //                 {
                //                     name:'---',
                //                     font:'毛细管阵列可互换的灵活性，实现不同的分辨率与应用'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'可以加载3块96孔样品板，在无人干预的情况下实现288个样本分析，并可以任何顺序开展检测'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'可以装载两种不同的凝胶基质，实现无需人工干预的不同样本类型分析间的切换'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'专业软件配合不同试剂盒提供基因组DNA完整值（GQN）、RNA完整值(RQN)'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'应用覆盖基因组DNA、大/小片段DNA、cfDNA、NGS文库质控、微卫星、PCR片段、RNA等'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'可靠的弥散条带分析可提供准确的摩尔浓度计算结果'
                //                 },
                //                 {
                //                     name:'---',
                //                     font:'清晰的结果，分辨率高达3bp'
                //                 }
                //             ]
                //         }
                //       ]
                // },
                {
                    id:301,
                    title:"恒温PCR预混液（恒温扩增试剂盒）",
                    img:[
                        {
                            img:require('../../assets/image/product/PCR_kit@2x.webp')
                       }
                    ],
                    data:[
                        {
                            id:1,
                            title:'产品介绍',
                            instroduce:[
                                {
                                    name:'产品名称',
                                    font:'恒温PCR预混液'
                                },
                                {
                                    name:'产品规格',
                                    font:'24人份/盒、48人份/盒'
                                },
                                {
                                    name:'适用样本类型',
                                    font:'肺炎支原体核酸'
                                },
                                {
                                    name:'医疗器械备案号',
                                    font:'川蓉械备20210166号'
                                },
                                {
                                    name:'配套可选试剂',
                                    font:'核酸提取试剂（川蓉械备20210165号）'
                                },
                            ],
                           
                        },
                        {
                            id:2,
                            title:'预期用途',
                            instroduce:[
                                {
                                    name:'',
                                    font:'用于待测肺炎支原体核酸样本的的体外扩增。扩增的产物可用作胶体金显色反应、测序等临床体外检测使用。'
                                }
                            ],
                        },
                        {
                            id:3,
                            title:'产品优势',
                            instroduce:[
                                {
                                    name:'',
                                    font:'恒温条件下即可实现对核酸的指数扩增。'
                                },
                                {
                                    name:'',
                                    font:'反应时间30min内即可完成扩增。'
                                }
                            ]
                        }
                      ]
                },
                {
                    id:401,
                    title:"测序反应通用试剂盒",
                    img:[
                        {
                            img:require('@/assets/image/product/2buffer_kit@2x.webp')
                       }
                    ],
                    data:[
                        {
                            id:1,
                            title:'产品介绍',
                            instroduce:[
                                {
                                    name:'产品名称',
                                    font:'测序反应通用试剂盒'
                                },
                                {
                                    name:'产品规格',
                                    font:'24人份/盒、48人份/盒'
                                },
                                {
                                    name:'适用样本类型',
                                    font:'PCR产物/核酸'
                                },
                            ],
                           
                        },
                        {
                            id:2,
                            title:'预期用途',
                            instroduce:[
                                {
                                    name:'',
                                    font:'用于高通量测序并获取样本序列信息，不适用于全基因组测序。'
                                }
                            ],
                        },
                        {
                            id:3,
                            title:'产品优势',
                            instroduce:[
                                {
                                    name:'',
                                    font:'文库扩增所得为长片段，适用于第三代测序平台直接测序。'
                                },
                                {
                                    name:'',
                                    font:'文库构建具有高稳定性和重复性。'
                                },
                                {
                                    name:'',
                                    font:'文库转化效率更高，可对微量DNA样本进行高效的文库构建。'
                                }
                            ]
                        }
                      ]
                },
                {
                    id:402,
                    title:"缓冲液（三代测序建库试剂盒）",
                    img:[
                        {
                            img:require('@/assets/image/product/3buffer_kit@2x.webp')
                       }
                    ],
                    data:[
                        {
                            id:1,
                            title:'产品介绍',
                            instroduce:[
                                {
                                    name:'产品名称',
                                    font:'缓冲液'
                                },
                                {
                                    name:'产品规格',
                                    font:'24人份/盒、48人份/盒'
                                },
                                {
                                    name:'适用样本类型',
                                    font:'PCR产物/核酸'
                                },
                                {
                                    name:'医疗器械备案号',
                                    font:'川蓉械备20200100号'
                                },
                            ],
                           
                        },
                        {
                            id:2,
                            title:'预期用途',
                            instroduce:[
                                {
                                    name:'',
                                    font:'构建的文库适用于牛津纳米孔（Oxford nanopore Technologies ，ONT）及其他各型三代测序仪测序。'
                                }
                            ],
                        },
                        {
                            id:3,
                            title:'产品优势',
                            instroduce:[
                                {
                                    name:'',
                                    font:'文库扩增所得为长片段，适用于第三代测序平台直接测序。'
                                },
                                {
                                    name:'',
                                    font:'文库构建具有高稳定性和重复性。'
                                },
                                {
                                    name:'',
                                    font:'文库转化效率更高，可对微量DNA样本进行高效的文库构建。'
                                }
                            ]
                        }
                      ]
                },
                {
                    id:501,
                    title:"心梗三项联检试剂盒（胶体金蛋白检测）",
                    img:[
                        {
                            img:require('../../assets/image/product/xingeng_kit@2x.webp')
                       }
                    ],
                    data:[
                        {
                            id:1,
                            title:'产品介绍',
                            instroduce:[
                                {
                                    name:'产品名称',
                                    font:'心梗三项联检试剂盒'
                                },
                                {
                                    name:'产品规格',
                                    font:'5人份/盒'        
                                },
                                {
                                    name:'适用样本类型',
                                    font:'血清、血浆、全血'
                                },
                            ],
                           
                        },
                        {
                            id:2,
                            title:'预期用途',
                            instroduce:[
                                {
                                    name:'',
                                    font:'心梗三项检测试剂用于临床体外定性检测人血清、血浆或全血中心肌肌钙蛋白I（cTnI）、肌红蛋白（Myo）和肌酸激酶同工酶（CK-MB）；'
                                }
                            ],
                        },
                        {
                            id:3,
                            title:'检测内容',
                            instroduce:[
                                {
                                    name:'心肌肌钙蛋白IcTnI',
                                    font:'是肌钙蛋白-原肌球蛋白调节复合物中的抑制亚单位，抑制肌动蛋白的ATP酶活性使与肌球蛋白偶联，使心肌和骨骼肌松弛，有防止肌肉收缩的作用。心肌损伤后4-8小时开始在血液中升高，能够在血液中保持5-10天，提供较长的窗口期，是目前诊断心肌损伤、坏死时特异性最强和灵敏性较高的生物标志物，被誉为诊断AMI（急性心肌梗死）的“金标准”，急性冠脉综合征(ACS)危险分层的首选标志物。'
                                },
                                {
                                    name:'肌红蛋白Myo',
                                    font:'一种小分子色素蛋白，可与氧可逆性结合，在肌细胞内有转运和贮存氧的作用。不通过淋巴结可直接进入血液循环，只存在于心肌及骨骼肌内，心肌损伤发生6h以内心肌损伤标志物中高度敏感的早期标志物。'
                                },
                                {
                                    name:'肌酸激酶同工酶CK-MB',
                                    font:'主要存在骨骼肌和心肌细胞，心肌梗死发生时，6小时内升高，24小时达高峰，3-4日内恢复正常，诊断特异性高，主要存在于心肌细胞的外浆层，是临床诊断心肌损伤的心肌酶谱中具特异性的酶。'
                                }
                            ]
                        },  {
                            id:4,
                            title:'检测意义',
                            instroduce:[
                                {
                                    name:'',
                                    font:'cTnI、Myo、CK-MB三项检测指标综合应用于AMI的诊断，在特定时间段独立或联合检测可大大提高检测的敏感性和特异性，是目前公认最好的心肌损伤标志物，对于诊断心肌梗死、评价溶栓治疗效果、评价再栓塞或栓塞范围及危险程度都具有重要意义，对临床诊断具有较大的参考价值。'
                                }
                            ]
                        }
                      ]
                },
                {
                    id:502,
                    title:"肺炎支原体核酸胶体金检测卡",
                    img:[
                        {
                            img:require('@/assets/image/product/pneumonia_kit@2x.webp')
                        },
                    ],
                    data:[
                        {
                            id:1,
                            title:'产品介绍',
                            instroduce:[
                                {
                                    name:'产品名称',
                                    font:'肺炎支原体核酸胶体金检测卡'
                                },
                                {
                                    name:'产品规格',
                                    font:'1人份/盒'
                                },
                                {
                                    name:'适用样本类型',
                                    font:'鼻咽拭子'
                                },
                                {
                                    name:'CE认证',
                                    font:'已认证'
                                },
                                {
                                    name:'配套可选试剂盒',
                                    font:'核酸提取试剂（川蓉械备20210165号）、恒温PCR预混液（川蓉械备20210166号）'
                                },
                            ],
                           
                        },
                        {
                            id:2,
                            title:'预期用途',
                            instroduce:[
                                {
                                    name:'',
                                    font:'试剂盒用于定性检测鼻咽拭子中肺炎支原体核酸（DNA），检测结果可用于肺炎支原体辅助诊断和指导用药。'
                                }
                            ],
                        },
                        {
                            id:3,
                            title:'产品优势',
                            instroduce:[
                                {
                                    name:'',
                                    font:'采用恒温扩增法，以特异性基因为靶标，特异性高。'
                                },
                                {
                                    name:'',
                                    font:'灵敏度高，可检测最低浓度为100copies/ml。'
                                },
                                {
                                    name:'',
                                    font:'快速定性筛查，30-50min左右即可判断结果。'
                                },
                                {
                                    name:'',
                                    font:'不需特殊仪器。'
                                }
                            ]
                        }
                      ]
                },
                {
                    id:503,
                    title:"甲型流感病毒核酸胶体金检测卡",
                    img:[
                        {
                            img:require('@/assets/image/product/jialiu_kIt@2x.webp')
                        },
                    ],
                    data:[
                        {
                            id:1,
                            title:'产品介绍',
                            instroduce:[
                                {
                                    name:'产品名称',
                                    font:'甲型流感病毒核酸胶体金检测卡'
                                },
                                {
                                    name:'产品规格',
                                    font:'1人份/盒'
                                },
                                {
                                    name:'适用样本类型',
                                    font:'鼻咽拭子'
                                },
                                {
                                    name:'欧盟认证',
                                    font:'已认证'
                                },
                                {
                                    name:'配套可选试剂盒',
                                    font:'核酸提取试剂（川蓉械备20210165号）'
                                },
                            ],
                           
                        },
                        {
                            id:2,
                            title:'预期用途',
                            instroduce:[
                                {
                                    name:'',
                                    font:'试剂盒用于定性检测鼻咽拭子中甲型流感N1和N2两种亚型核酸（RNA），检测结果可用于甲型流感病毒的辅助诊断和指导用药。'
                                }
                            ],
                        },
                        {
                            id:3,
                            title:'产品优势',
                            instroduce:[
                                {
                                    name:'',
                                    font:'采用恒温扩增法，以特异性基因为靶标，特异性高。'
                                },
                                {
                                    name:'',
                                    font:'灵敏度高，可检测最低浓度为100copies/ml。'
                                },
                                {
                                    name:'',
                                    font:'快速定性筛查，30-50min左右即可判断结果。'
                                },
                                {
                                    name:'',
                                    font:'不需特殊仪器。'
                                }
                            ]
                        }
                      ]
                },
                {
                    id:504,
                    title:"新型冠状病毒核酸胶体金检测卡",
                    img:[
                        {
                            img:require('@/assets/image/product/SARS_kit@2x.webp')
                        },
                    ],
                    data:[
                        {
                            id:1,
                            title:'产品介绍',
                            instroduce:[
                                {
                                    name:'产品名称',
                                    font:'新型冠状病毒核酸胶体金检测卡'
                                },
                                {
                                    name:'产品规格',
                                    font:'1人份/盒'
                                },
                                {
                                    name:'适用样本类型',
                                    font:'鼻咽拭子'
                                },
                                {
                                    name:'欧盟认证',
                                    font:'已认证'
                                },
                                {
                                    name:'配套可选试剂盒',
                                    font:'核酸提取试剂（川蓉械备20210165号）'
                                },
                            ],
                           
                        },
                        {
                            id:2,
                            title:'预期用途',
                            instroduce:[
                                {
                                    name:'',
                                    font:'试剂盒用于定性检测鼻咽拭子中新型冠状病毒核酸（RNA），检测结果可用于新型冠状病毒的辅助诊断和指导用药。'
                                }
                            ],
                        },
                        {
                            id:3,
                            title:'产品优势',
                            instroduce:[
                                {
                                    name:'',
                                    font:'采用恒温扩增法，以特异性基因为靶标，特异性高。'
                                },
                                {
                                    name:'',
                                    font:'灵敏度高，可检测最低浓度为100copies/ml。'
                                },
                                {
                                    name:'',
                                    font:'快速定性筛查，30-50min左右即可判断结果。'
                                },
                                {
                                    name:'',
                                    font:'不需特殊仪器。'
                                }
                            ]
                        }
                      ]
                },
            ]
       }
    }
    export default products
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import NavBread from "@/components/nav-Bread"
import lazyPlugin from 'vue3-lazy'
import 'element-plus/theme-chalk/display.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import animated from 'animate.css'
// import "./assets/font/font.scss"
// import "swiper/swiper-bundle.css";

// createApp(App).use(store).use(router).mount('#app')
const app=createApp(App);
app.use(store);
app.use(router);
app.use(animated);
app.config.warnHandler = () => null;

app.mount("#app")
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

// 懒加载
app.use(lazyPlugin,{
    // loading: require('@/assets/image/load.gif'), // 图片加载时默认图片
      // error: require('@/assets/image/home/NHK-A5.png')// 图片加载失败时默认图片
  })

  // 全局注册组件
// app.component('nav-Bread',NavBread)


var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
// 测试统计
// (function() {
//   var hm = document.createElement("script");
//   hm.src = "https://hm.baidu.com/hm.js?38c8833bd6d2d492ca3cbff716e6b8d5";
//   var s = document.getElementsByTagName("script")[0]; 
//   s.parentNode.insertBefore(hm, s);
// })();

(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?421de4e23f55007789d272ffe72e54e7";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

_hmt.push(['_requirePlugin', 'UrlChangeTracker', {
  // shouldTrackUrlChange: function (newPath, oldPath) {
	// return newPath && oldPath;
  // }}
  shouldTrackUrlChange: function (newPath, oldPath) {
	  newPath = newPath.split('?')[0];
	  oldPath = oldPath.split('?')[0];
    return newPath != oldPath;
  }}
]);

const introduce={
  // namespaced: true,
    state:{
        introduceData:{
            // code:200,
            datas:[
             {
              id:1,
              title:'公司介绍',
            //   img:require("@/assets/image/about/Company_Intro@1x.webp"),
              title:"关于诺瀚康",
              about:[
                  {
                    p:'四川诺瀚康科技有限公司成立于2017年1月，位于四川成都高新区天府大道北段1480号。诺瀚康是面向生命科学及人类健康领域的创新科技公司，致力于提供基因检测服务、研发生产体外诊断试剂和医疗设备及进出口业务。'
                  },
                  {
                    p:'公司在成都、济南、北京三地拥有专业的第三方医学检验机构，取得了《医疗机构执业许可证》，拥有符合医疗卫生从业标准的专业实验室。实验室建设以ISO15189、ISO17025和CAP等为标准，建立健全与国际标准接轨的质量管理体系。实验室配备了高通量二代测序平台、ONT三代测序平台、qPCR 、高性能计算机集群等软硬件，具备完整的基因检测技术研发和应用的硬件条件。'
                  },
                  {
                    p:'公司拥有大批高学历检测和研发团队，自主研发了十多种临床诊断产品和医疗设备，在国内和境外多个国家销售。同时我们代理了安捷伦，默克等品牌的进口业务，为中国的科学研究和检测等项目提供多种设备和化学试剂。'
                  }
              ],
              business:[{
                title:'主营业务',
                item:[
                  {
                    id:1,
                    h:'医疗设备/体外诊断试剂',
                    font:[
                      {
                        item:'全自动毛细管电动仪'
                      },
                      {
                        item:'核酸提取纯化试剂盒'
                      },
                      {
                        item:'缓冲液（三代建库试剂盒）'
                      },
                      {
                        item:'恒温PCR预混液'
                      },
                      {
                        item:'核酸提取试剂'
                      },
                      {
                        item:'肺炎支原体/甲型流感病毒/新型冠状病毒胶体金检测卡'
                      }
                    ]
                  },
                   {
                    id:2,
                    h:'国际进出口贸易',
                    font:[
                      {
                        item:'人体免疫检测试剂盒'
                      },
                      {
                        item:'动物免疫检测试剂盒'
                      },
                      {
                        item:'通用免疫检测试剂盒'
                      },
                      {
                        item:'肺炎支原体/甲型流感病毒/新型冠状病毒胶体金检测卡'
                      },
                      {
                        item:'安捷伦，默克等品牌进口销售代理'
                      }
                    ]
                  },
                   {
                    id:3,
                    h:'基因检测服务',           
                    font:[
                      {
                        item:'肿瘤靶向用药基因测序服务'
                      },
                      {
                        item:'病原微生物感染测序分析服务'
                      },
                      {
                        item:'遗传疾病的测序筛查服务'
                      },
                      {
                        item:'科研测序分析服务'
                      }
                    ]
                  }
                ]
              }]
              },
          
              {
                id:2,
                title:'公司价值观',
                // img:require("@/assets/image/about/Company_Values@1x.webp"),
                fonts:[
                  {
                    h:'愿景',
                    p:"引领基因科技创新 提供精准医疗服务"
                  },
                   {
                    h:'使 命',
                    p:"专注基因检测 呵护生命健康"
                  },
                   {
                    h:'创 造',
                    p:"技术创新 造福人类"
                  }
                ]
              },
          
              {
                id:3,
                title:'公司环境',
                // img:require("@/assets/image/about/Company_envir@1x.webp"),
                environment:[
                  {
                    id:1,
                    title:'专业',
                    // images:[
                    //   {
                    //     img:require("@/assets/image/about/envir_spe_1@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/envir_spe_2@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/envir_spe_3@1x.webp"),
                    //   }
                    // ]
                  },
                  {
                    id:2,
                    title:'严谨',
                    // images:[
                    //   {
                    //     img:require("@/assets/image/about/envir_preci_1@1x.webp"),
                    //   },
                    //    {
                    //     img:require("@/assets/image/about/envir_preci_2@1x.webp"),
                    //   },
                    //    {
                    //     img:require("@/assets/image/about/envir_preci_3@1x.webp"),
                    //   }
                    // ]
                  },
                  {
                    id:3,
                    title:'科技',
                    // images:[
                    //   {
                    //     img:require("@/assets/image/about/envir_tech_1@1x.webp"),
                    //   },
                    //    {
                    //     img:require("@/assets/image/about/envir_tech_2@1x.webp"),
                    //   },
                    //    {
                    //     img:require("@/assets/image/about/envir_tech_3@1x.webp"),
                    //   }
                    // ]
                  }
                ]
              },
          
              {
                id:4,
                title:'公司资质',
                // img:require("@/assets/image/about/Company_quali@1x.webp"),
               qualifications:[
                  {
                    id:1,
                    title:'疗机构执业许可证',
                    // images:[
                    //   {
                    //     img:require("@/assets/image/about/quali_License@1x.webp"),
                    //   }
                    // ]
                  },
                  {
                    id:2,
                    title:'空间质评证书',
                    // images:[
                    //   {
                    //     img:require("@/assets/image/about/quali_space_1@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/quali_space_2@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/quali_space_3@1x.webp"),
                    //   }
                    // ]
                  },
                  {
                    id:3,
                    title:'空间质量评价',
                    // images:[
                    //   {
                    //     img:require("@/assets/image/about/qauli_appraise_1@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/qauli_appraise_2@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/qauli_appraise_3@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/qauli_appraise_4@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/qauli_appraise_8@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/qauli_appraise_9@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/qauli_appraise_7@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/qauli_appraise_10@1x.webp"),
                    //   },
                    //   {
                    //     img:require("@/assets/image/about/qauli_appraise_6@1x.webp"),
                    //   }
                    // ]
                  }
                ]
              }
            ]
          }
    }
}
export default introduce
// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
const header=()=>import(/* webpackChunkName: "about" */ '@/components/header');
const model=() => import(/* webpackChunkName: "about" */ '@/components/header/model.vue');
const footer=() => import(/* webpackChunkName: "about" */ '@/components/footer');
const NavBread=() => import('@/components/navBread/');

const routes = [
   // 重定向
   {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    // components: IndexView
    components:{ 
      header:header,
      model:model,
      footer:footer,
      default: ()=>import("@/views/home"),
    },
    meta: {
      title: '首页'
    }
  },

  {
    path: '/introduce',
    name: 'introduce',
    components:{ 
      header:header,
      model:model,
      footer:footer,
      default: () => import('@/views/introduce'),
    },
    meta: {
      title: '企业介绍'
    },
    children:[
      {
        path:'company',
        name:'company',
        meta:{
          // title:'公司价值观',
          title: '企业介绍'
        },
        components:{
          default:()=>import('@/views/introduce/four/company.vue')
        }
      },
      {
        path:'value',
        name:'value',
        meta:{
          // title:'公司价值观',
          title: '企业介绍'
        },
        components:{
          default:()=>import('@/views/introduce/four/value.vue')
        }
      },
      {
        path:'environment',
        name:'environment',
        meta:{
          // title:'公司环境',
          title: '企业介绍'
          
        },
        components:{
          default:()=>import('@/views/introduce/four/environment.vue'),
          environment:()=>import('@/views/introduce/four/environment.vue')
        }
      },
      {
        path:'qualifications',
        name:'qualifications',
        meta:{
          // title:'公司资质',
          title: '企业介绍'
        },
        components:{
          default:()=>import('@/views/introduce/four/qualifications.vue')
        }
      },
    ]
  },

  {
    path: '/product',
    name: 'product',
    components:{ 
      header:header,
      model:model,
      footer:footer,
      // NavBread:NavBread,
      default: () => import('@/views/product'),
    },
    meta: {
      title: '产品中心'
    }
  },
  {
    path: '/products',
    name: 'products',
    components:{ 
      header:header,
      model:model,
      footer:footer,
      default: () => import('@/views/products'),
    },
    meta: {
      title: '产品中心'
    },
    children:[
      {
        path:'details/:id',
        // path:'details',
        name:'details',
        meta:{
          title: '产品中心'
        },
        components:{
          default:()=>import("@/views/details")
        }
      }
    ]
  },

  // 用路由，css来跳转
  {
    path: '/trade',
    name: 'trade',
    components:{ 
      header:header,
      model:model,
      footer:footer,
      default: () => import('@/views/trade'),
    },
    meta: {
      title: '国际贸易'
    }
  },
  {
    path: '/trade1',
    name: 'trade1',
    components:{ 
      header:header,
      model:model,
      footer:footer,
      default: () => import('@/views/trade'),
    },
    meta: {
      title: '国际贸易'
    }
  },
  {
    path: '/trade2',
    name: 'trade2',
    components:{ 
      header:header,
      model:model,
      footer:footer,
      default: () => import('@/views/trade'),
    },
    meta: {
      title: '国际贸易'
    }
  },

  // 同上service
  {
    path: '/service',
    name: 'service',
    components:{ 
      header:header,
      model:model,
      footer:footer,
      default: () => import('@/views/service'),
    },
    meta: {
      title: '医疗服务'
    }
  },
  {
    path: '/box1',
    name: '/box1',
    components:{ 
      header:header,
      model:model,
      footer:footer,
      default: () => import('@/views/service'),
    },
    meta: {
      title: '医疗服务'
    }
  },
  {
    path: '/box2',
    name: '/box2',
    components:{ 
      header:header,
      model:model,
      footer:footer,
      default: () => import('@/views/service'),
    },
    meta: {
      title: '医疗服务'
    }
  },
  {
    path: '/box3',
    name: 'box3',
    components:{ 
      header:header,
      model:model,
      footer:footer,
      default: () => import('@/views/service'),
    },
    meta: {
      title: '医疗服务'
    }
  },

  {
    path: '/contact',
    name: 'contact',
    components:{ 
      header:header,
      model:model,
      footer:footer,
      default: () => import('@/views/contact'),
    },
    meta: {
      title: '联系我们'
    }
  },

]
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

export default router

const data={
nameespaced: true,
   state:{
    meun:[
        {
            id:1,
            title:'首页',
            pathname:'home',
            child:[]

        },
        {
            id:2,
            title:'企业介绍',
            pathname:'company',
            child:[
                {
                    id:2,
                    title:'公司介绍',
                    pathname:'company',
                    path:'/introduce/company',
                    ppath:'/introduce/company',
                },
                {
                    id:2,
                    title:'公司价值观',
                    pathname:'value',
                    path:'/introduce/value',
                    ppath:'/introduce/value',
                },
                {
                    id:2,
                    title:'公司环境',
                    pathname:'environment',
                    path:'/introduce/environment',
                    ppath:'/introduce/environment',
                },
                {
                    id:2,
                    title:'公司资质',
                    pathname:'qualifications',
                    path:'/introduce/qualifications',
                    ppath:'/introduce/qualifications',
                }
            ]
        },
        {
            id:3,
            title:'产品中心',
            pathname:'product',
            path:'/product',
            child:[
                {
                    id:3,
                    pathid:101,
                    // title:'NHK-A5',
                    title:"全自动毛细管电泳仪",
                    pathname:'details',
                    children:[
                        {
                            id:101,
                            title:'NHK-A5',
                        }
                    ]
                },
                {
                    id:3,
                    pathid:201,
                    title:'核酸提取试剂盒',
                    pathname:'details',
                    children:[
                        {   
                            id:201,
                            title:'游离DNA提取试剂盒',
                        },
                        // 未申请
                        // {   
                        //     id:202,
                        //     title:'游离DNA提取分析试剂盒',
                        // },
                        {   
                            id:203,
                            title:'微生物核酸提取试剂盒',
                        }
                    ]
                },
                {
                    id:3,
                    pathid:301,
                    title:'扩增试剂',
                    pathname:'details',
                    children:[
                        {   
                            id:301,
                            title:'恒温PCR预混液（恒温扩增试剂盒）',
                        }
                    ]
                },
                {
                    id:3,
                    pathid:401,
                    title:'测序建库试剂盒',
                    pathname:'details',
                    children:[
                        {   
                            id:401,
                            title:'二代测序反应通用试剂盒',
                        },
                        {   
                            id:402,
                            title:'三代测序建库试剂盒',
                        },
                    ]
                },
                {
                    id:3,
                    pathid:501,
                    title:'胶体金检测试剂盒',
                    pathname:'details',
                    children:[
                        {   
                            id:501,
                            title:'心梗三项联检试剂盒（胶体金免疫层析法）',
                        },
                        {   
                            id:502,
                            title:'肺炎支原体核酸胶体金检测卡',
                        },
                        {   
                            id:503,
                            title:'甲型流感病毒核酸胶体金检测卡',
                        },
                        {   
                            id:504,
                            title:'新型冠状病毒核酸胶体金检测卡',
                        }
                    ]
                }
            ]
        }, 
        {
            id:4,
            title:'医疗服务',
            pathname:'service',
            child:[
                {
                    id:4,
                    title:'肿瘤检测',
                    pathname:'service',
                    cssname:"#box2"
                },
                {
                    id:4,
                    title:'病原微生物检测',
                    pathname:'service',
                    cssname:"#box1"
                },
                {
                    id:4,
                    title:'科研服务',
                    pathname:'service',
                    cssname:"#box3"
                },
                // {
                //     id:4,
                //     title:'肿瘤检测',
                //     pathname:'service',
                //     cssname:"#box1"
                // },
                // {
                //     id:4,
                //     title:'病原微生物检测',
                //     pathname:'service',
                //     cssname:"#box2"
                // },
                // {
                //     id:4,
                //     title:'遗传学检测',
                //     pathname:'service',
                //     cssname:"#box3"
                // },
                // {
                //     id:4,
                //     title:'科研测序分析',
                //     pathname:'service',
                //     cssname:"#box3"
                // }
            ]
        },
        {
            id:5,
            title:'国际贸易',
            pathname:'trade',
            child:[
                {
                    id:5,
                    title:'人体检疫',
                    pathname:'trade',
                    cssname:"#trade"
                },
                {
                    id:5,
                    title:'动物检疫',
                    pathname:'trade',
                    cssname:"#trade1"
                },
                {
                    id:5,
                    title:'通用',
                    pathname:'trade',
                    cssname:"#trade2"
                }
            ]
        },
        {
            id:6,
            title:'联系我们',
            pathname:'contact',
            child:[
                // {
                //     id:6,
                //     title:'联系方式',
                //     pathname:'contact'
                // },
                // {
                //     id:6,
                //     title:'联系地址',
                //     pathname:'contact'
                // }
            ]
        }
    ],
    
   }
}
export default data